export const TERMINAL_BERTH = [
  {
    label: 'ANCHORAGE',
    value: 'ANCHORAGE',
    children: [
      {
        label: 'ACBTH',
        value: 'ACBTH'
      },
      {
        label: 'ACGP',
        value: 'ACGP'
      },
      {
        label: 'AMOW',
        value: 'AMOW'
      },
      {
        label: 'AEBA',
        value: 'AEBA'
      },
      {
        label: 'AEBB',
        value: 'AEBB'
      },
      {
        label: 'AEPBC',
        value: 'AEPBC'
      },
      {
        label: 'ASCB',
        value: 'ASCB'
      },
      {
        label: 'ASCA',
        value: 'ASCA'
      },
      {
        label: 'AEPBB',
        value: 'AEPBB'
      },
      {
        label: 'AESPA',
        value: 'AESPA'
      },
      {
        label: 'AEBC',
        value: 'AEBC'
      },
      {
        label: 'AEHA',
        value: 'AEHA'
      },
      {
        label: 'AEPA',
        value: 'AEPA'
      },
      {
        label: 'AEW',
        value: 'AEW'
      },
      {
        label: 'AEHB',
        value: 'AEHB'
      },
      {
        label: 'AEHC',
        value: 'AEHC'
      },
      {
        label: 'AWQI',
        value: 'AWQI'
      },
      {
        label: 'AWW',
        value: 'AWW'
      },
      {
        label: 'AWPA',
        value: 'AWPA'
      },
      {
        label: 'AWH',
        value: 'AWH'
      },
      {
        label: 'AWPB',
        value: 'AWPB'
      },
      {
        label: 'ARAFR',
        value: 'ARAFR'
      },
      {
        label: 'ARP',
        value: 'ARP'
      },
      {
        label: 'ASPLU',
        value: 'ASPLU'
      },
      {
        label: 'ASPP',
        value: 'ASPP'
      },
      {
        label: 'ASPH',
        value: 'ASPH'
      },
      {
        label: 'ASUBB',
        value: 'ASUBB'
      },
      {
        label: 'ASUEX',
        value: 'ASUEX'
      },
      {
        label: 'ASSPU',
        value: 'ASSPU'
      },
      {
        label: 'ASUBA',
        value: 'ASUBA'
      },
      {
        label: 'ASH',
        value: 'ASH'
      },
      {
        label: 'AVLCC',
        value: 'AVLCC'
      }
    ]
  },
  {
    label: 'TANJONG PAGAR',
    value: 'TANJONG PAGAR',
    children: [
      {
        label: 'T-01',
        value: 'T-01'
      },
      {
        label: 'T-02',
        value: 'T-02'
      },
      {
        label: 'T-06',
        value: 'T-06'
      }
    ]
  },
  {
    label: 'KEPPEL',
    value: 'KEPPEL',
    children: [
      {
        label: 'K-09',
        value: 'K-09'
      },
      {
        label: 'K-10',
        value: 'K-10'
      },
      {
        label: 'K-11',
        value: 'K-11'
      },
      {
        label: 'K-12',
        value: 'K-12'
      },
      {
        label: 'K-13',
        value: 'K-13'
      },
      {
        label: 'K-14',
        value: 'K-14'
      },
      {
        label: 'K-15',
        value: 'K-15'
      },
      {
        label: 'K-16',
        value: 'K-16'
      },
      {
        label: 'K-17',
        value: 'K-17'
      },
      {
        label: 'K-18',
        value: 'K-18'
      },
      {
        label: 'K-19',
        value: 'K-19'
      },
      {
        label: 'K-20',
        value: 'K-20'
      },
      {
        label: 'K-21',
        value: 'K-21'
      },
      {
        label: 'K-22',
        value: 'K-22'
      },
      {
        label: 'K-23',
        value: 'K-23'
      }
    ]
  },
  {
    label: 'BRANI',
    value: 'BRANI',
    children: [
      {
        label: 'B-01',
        value: 'B-01'
      },
      {
        label: 'B-02',
        value: 'B-02'
      },
      {
        label: 'B-03',
        value: 'B-03'
      },
      {
        label: 'B-04',
        value: 'B-04'
      },
      {
        label: 'B-05',
        value: 'B-05'
      },
      {
        label: 'B-06',
        value: 'B-06'
      },
      {
        label: 'B-07',
        value: 'B-07'
      },
      {
        label: 'B-08',
        value: 'B-08'
      },
      {
        label: 'B-09',
        value: 'B-09'
      }
    ]
  },
  {
    label: 'PASIR PANJANG',
    value: 'PASIR PANJANG',
    children: [
      {
        label: 'P01',
        value: 'P01'
      },
      {
        label: 'P02',
        value: 'P02'
      },
      {
        label: 'P03',
        value: 'P03'
      },
      {
        label: 'P04',
        value: 'P04'
      },
      {
        label: 'P05',
        value: 'P05'
      },
      {
        label: 'P06',
        value: 'P06'
      },
      {
        label: 'P07',
        value: 'P07'
      },
      {
        label: 'P08',
        value: 'P08'
      },
      {
        label: 'P09',
        value: 'P09'
      },
      {
        label: 'P10',
        value: 'P10'
      },
      {
        label: 'P11',
        value: 'P11'
      },
      {
        label: 'P12',
        value: 'P12'
      },
      {
        label: 'P13',
        value: 'P13'
      },
      {
        label: 'P14',
        value: 'P14'
      },
      {
        label: 'P15',
        value: 'P15'
      },
      {
        label: 'P16',
        value: 'P16'
      },
      {
        label: 'P17',
        value: 'P17'
      },
      {
        label: 'P18',
        value: 'P18'
      },
      {
        label: 'P19',
        value: 'P19'
      },
      {
        label: 'P20',
        value: 'P20'
      },
      {
        label: 'P21',
        value: 'P21'
      },
      {
        label: 'P22',
        value: 'P22'
      },
      {
        label: 'P23',
        value: 'P23'
      },
      {
        label: 'P24',
        value: 'P24'
      },
      {
        label: 'P25',
        value: 'P25'
      },
      {
        label: 'P26',
        value: 'P26'
      },
      {
        label: 'P27',
        value: 'P27'
      },
      {
        label: 'P28',
        value: 'P28'
      },
      {
        label: 'P29',
        value: 'P29'
      },
      {
        label: 'P30',
        value: 'P30'
      },
      {
        label: 'P31',
        value: 'P31'
      },
      {
        label: 'P32',
        value: 'P32'
      },
      {
        label: 'P33',
        value: 'P33'
      },
      {
        label: 'P34',
        value: 'P34'
      },
      {
        label: 'P35',
        value: 'P35'
      },
      {
        label: 'P36',
        value: 'P36'
      },
      {
        label: 'P37',
        value: 'P37'
      },
      {
        label: 'P38',
        value: 'P38'
      },
      {
        label: 'P39',
        value: 'P39'
      },
      {
        label: 'P40',
        value: 'P40'
      },
      {
        label: 'P41',
        value: 'P41'
      }
    ]
  },
  {
    label: 'JURONG PORT',
    value: 'JURONG PORT',
    children: [
      {
        label: 'J01',
        value: 'J01'
      },
      {
        label: 'J02',
        value: 'J02'
      },
      {
        label: 'J02A',
        value: 'J02A'
      },
      {
        label: 'J03',
        value: 'J03'
      },
      {
        label: 'J03A',
        value: 'J03A'
      },
      {
        label: 'J04',
        value: 'J04'
      },
      {
        label: 'J04A',
        value: 'J04A'
      },
      {
        label: 'J05',
        value: 'J05'
      },
      {
        label: 'J06',
        value: 'J06'
      },
      {
        label: 'J07',
        value: 'J07'
      },
      {
        label: 'J08',
        value: 'J08'
      },
      {
        label: 'J09',
        value: 'J09'
      },
      {
        label: 'J10',
        value: 'J10'
      },
      {
        label: 'J11',
        value: 'J11'
      },
      {
        label: 'J12',
        value: 'J12'
      },
      {
        label: 'J12A',
        value: 'J12A'
      },
      {
        label: 'J12B',
        value: 'J12B'
      },
      {
        label: 'J13',
        value: 'J13'
      },
      {
        label: 'J14',
        value: 'J14'
      },
      {
        label: 'J15',
        value: 'J15'
      },
      {
        label: 'J16',
        value: 'J16'
      },
      {
        label: 'J-17',
        value: 'J-17'
      },
      {
        label: 'J-18',
        value: 'J-18'
      },
      {
        label: 'J-19',
        value: 'J-19'
      },
      {
        label: 'J-20',
        value: 'J-20'
      },
      {
        label: 'J-21',
        value: 'J-21'
      },
      {
        label: 'J-22',
        value: 'J-22'
      },
      {
        label: 'J-23',
        value: 'J-23'
      },
      {
        label: 'J-24',
        value: 'J-24'
      },
      {
        label: 'J-25',
        value: 'J-25'
      }
    ]
  },
  {
    label: 'OIL TANKING',
    value: 'OIL TANKING',
    children: [
      {
        label: 'OTK1',
        value: 'OTK1'
      },
      {
        label: 'OTK2',
        value: 'OTK2'
      },
      {
        label: 'OTK3',
        value: 'OTK3'
      },
      {
        label: 'OTK4',
        value: 'OTK4'
      },
      {
        label: 'OTK5',
        value: 'OTK5'
      },
      {
        label: 'OTK6',
        value: 'OTK6'
      },
      {
        label: 'OTK7',
        value: 'OTK7'
      },
      {
        label: 'OTK8',
        value: 'OTK8'
      },
      {
        label: 'OTK9',
        value: 'OTK9'
      },
      {
        label: 'OTK10',
        value: 'OTK10'
      },
      {
        label: 'OTK11',
        value: 'OTK11'
      },
      {
        label: 'OTK12',
        value: 'OTK12'
      },
      {
        label: 'OTK18',
        value: 'OTK18'
      },
      {
        label: 'OTK19',
        value: 'OTK19'
      },
      {
        label: 'OTK20',
        value: 'OTK20'
      },
      {
        label: 'OTK21',
        value: 'OTK21'
      },
      {
        label: 'OTK22',
        value: 'OTK22'
      }
    ]
  },
  {
    label: 'BANYAN HORIZON',
    value: 'BANYAN HORIZON',
    children: [
      {
        label: 'OBH1',
        value: 'OBH1'
      },
      {
        label: 'OBH2',
        value: 'OBH2'
      },
      {
        label: 'OBH3',
        value: 'OBH3'
      },
      {
        label: 'OBH4',
        value: 'OBH4'
      }
    ]
  },
  {
    label: 'VOPAK BANYAN',
    value: 'VOPAK BANYAN',
    children: [
      {
        label: 'OBV1',
        value: 'OBV1'
      },
      {
        label: 'OBV2',
        value: 'OBV2'
      },
      {
        label: 'OBV3',
        value: 'OBV3'
      },
      {
        label: 'OBV4',
        value: 'OBV4'
      },
      {
        label: 'OBV5',
        value: 'OBV5'
      },
      {
        label: 'OBV6',
        value: 'OBV6'
      },
      {
        label: 'OBV7',
        value: 'OBV7'
      }
    ]
  },
  {
    label: 'HELIOS TERMINAL',
    value: 'HELIOS TERMINAL',
    children: [
      {
        label: 'OHT1',
        value: 'OHT1'
      },
      {
        label: 'OHT2',
        value: 'OHT2'
      },
      {
        label: 'OHT3',
        value: 'OHT3'
      },
      {
        label: 'OHT4',
        value: 'OHT4'
      },
      {
        label: 'OHT5',
        value: 'OHT5'
      },
      {
        label: 'OHT6',
        value: 'OHT6'
      }
    ]
  },
  {
    label: 'UNIVERSAL TERMINAL',
    value: 'UNIVERSAL TERMINAL',
    children: [
      {
        label: 'OMU1',
        value: 'OMU1'
      },
      {
        label: 'OMU2',
        value: 'OMU2'
      },
      {
        label: 'OMU3',
        value: 'OMU3'
      },
      {
        label: 'OMU4',
        value: 'OMU4'
      },
      {
        label: 'OMU5',
        value: 'OMU5'
      },
      {
        label: 'OMU6',
        value: 'OMU6'
      },
      {
        label: 'OMU7',
        value: 'OMU7'
      },
      {
        label: 'OMU8',
        value: 'OMU8'
      },
      {
        label: 'OMU9',
        value: 'OMU9'
      },
      {
        label: 'OMU10',
        value: 'OMU10'
      },
      {
        label: 'OMU11',
        value: 'OMU11'
      },
      {
        label: 'OMU12',
        value: 'OMU12'
      },
      {
        label: 'OMU13',
        value: 'OMU13'
      },
      {
        label: 'OMU14',
        value: 'OMU14'
      },
      {
        label: 'OMU15',
        value: 'OMU15'
      },
      {
        label: 'OMU16',
        value: 'OMU16'
      },
      {
        label: 'OMU17',
        value: 'OMU17'
      }
    ]
  },
  {
    label: 'SERAYA POWER STATION',
    value: 'SERAYA POWER STATION',
    children: [
      {
        label: 'OSP1A',
        value: 'OSP1A'
      },
      {
        label: 'OSP1B',
        value: 'OSP1B'
      },
      {
        label: 'OSP2A',
        value: 'OSP2A'
      },
      {
        label: 'OSP2B',
        value: 'OSP2B'
      },
      {
        label: 'OSPS1',
        value: 'OSPS1'
      },
      {
        label: 'OSPS2',
        value: 'OSPS2'
      },
      {
        label: 'OSPS3',
        value: 'OSPS3'
      },
      {
        label: 'OSPS4',
        value: 'OSPS4'
      }
    ]
  },
  {
    label: 'VOPAK SEBAROK',
    value: 'VOPAK SEBAROK',
    children: [
      {
        label: 'OSV2',
        value: 'OSV2'
      },
      {
        label: 'OSV3',
        value: 'OSV3'
      },
      {
        label: 'OSV4',
        value: 'OSV4'
      },
      {
        label: 'OSV5',
        value: 'OSV5'
      },
      {
        label: 'OSV6',
        value: 'OSV6'
      },
      {
        label: 'OSV7',
        value: 'OSV7'
      },
      {
        label: 'OSV8',
        value: 'OSV8'
      },
      {
        label: 'OSV9',
        value: 'OSV9'
      },
      {
        label: 'OSV10',
        value: 'OSV10'
      }
    ]
  },
  {
    label: 'TANK STORE',
    value: 'TANK STORE',
    children: [
      {
        label: 'OTS1',
        value: 'OTS1'
      },
      {
        label: 'OTS2',
        value: 'OTS2'
      },
      {
        label: 'OTS3',
        value: 'OTS3'
      },
      {
        label: 'OTS4',
        value: 'OTS4'
      },
      {
        label: 'OTS5',
        value: 'OTS5'
      },
      {
        label: 'OTS5E',
        value: 'OTS5E'
      },
      {
        label: 'OTS5W',
        value: 'OTS5W'
      },
      {
        label: 'OTS6',
        value: 'OTS6'
      },
      {
        label: 'OTS6E',
        value: 'OTS6E'
      },
      {
        label: 'OTS6W',
        value: 'OTS6W'
      },
      {
        label: 'OTS7',
        value: 'OTS7'
      },
      {
        label: 'OTS8',
        value: 'OTS8'
      },
      {
        label: 'OTS9',
        value: 'OTS9'
      },
      {
        label: 'OTS10',
        value: 'OTS10'
      }
    ]
  },
  {
    label: 'VOPAK TERMINAL',
    value: 'VOPAK TERMINAL',
    children: [
      {
        label: 'OVPJ1',
        value: 'OVPJ1'
      },
      {
        label: 'OVPJ2',
        value: 'OVPJ2'
      },
      {
        label: 'OVPJ3',
        value: 'OVPJ3'
      }
    ]
  },
  {
    label: 'CRUISE CENTRE',
    value: 'CRUISE CENTRE',
    children: [
      {
        label: 'CC01',
        value: 'CC01'
      },
      {
        label: 'CC02',
        value: 'CC02'
      }
    ]
  },
  {
    label: 'MARINA BAY CRUISE CENTRE',
    value: 'MARINA BAY CRUISE CENTRE',
    children: [
      {
        label: 'CM01',
        value: 'CM01'
      },
      {
        label: 'CM02',
        value: 'CM02'
      }
    ]
  },
  {
    label: 'TUAS MEGA',
    value: 'TUAS MEGA',
    children: [
      {
        label: 'MPT-203',
        value: 'MPT-203'
      },
      {
        label: 'MPT-204',
        value: 'MPT-204'
      }
    ]
  }
]
export const UNIT_OPTIONS = [
  { label: 'MT', value: 'MT' },
  { label: 'CBM', value: 'CBM' },
  { label: 'Barrels', value: 'BARRELS' },
  { label: 'Litres', value: 'LITRES' },
  { label: '%', value: '%' }
]
export const PAYMENT_TERM_OPTIONS = [
  { label: 'Credit Terms', value: 'CREDIT_TERMS' },
  { label: 'Cash In Advance', value: 'CASH_IN_ADVANCE' },
  { label: 'Cash On Delivery', value: 'CASH_ON_DELIVERY' },
  { label: 'Specific Date', value: 'SPECIFIC_DATE' }
]
export const CDNOTES_PAYMENT_TERM_OPTIONS = [
  { label: 'Credit Terms', value: 'CREDIT_TERMS' },
  { label: 'Specific Date', value: 'SPECIFIC_DATE' },
  { label: 'Immediate', value: 'IMMEDIATE' }
]
export const GST_TYPE_OPTIONS = [
  { label: 'Standard-Rated Supplies', value: 'S' },
  { label: 'Zero-Rated Supplies', value: 'Z' },
  { label: 'Exempt Supplies', value: 'E' },
  { label: 'Out-of-Scope Supplies', value: 'O' },
  { label: 'MFT Scheme', value: 'M' }
]
export const rule = [
  {
    required: true,
    message: 'This field is required',
    trigger: ['blur', 'change']
  }
]
// Invoice
export const INVOICE_VIEW = {
  MAIN_INVOICE: 'MAIN_INVOICE',
  CREATE_INVOICE: 'CREATE_INVOICE',
  VIEW_INVOICE: 'VIEW_INVOICE',
  EDIT_INVOICE: 'EDIT_INVOICE'
}
export const INVOICE_STATUS = {
  CREATED: 'CREATED',
  SENT: 'SENT',
  CANCELLED: 'CANCELLED',
  OUTSTANDING: 'OUTSTANDING',
  PAID: 'PAID',
  PENDING_CREDIT_AUTHORIZATION: 'PENDING_CREDIT_AUTHORIZATION',
  CREDIT_AUTHORIZATION_REJECTED: 'CREDIT_AUTHORIZATION_REJECTED',
  PENDING_AUTHORIZED_SIGNATORY_APPROVAL:
    'PENDING_AUTHORIZED_SIGNATORY_APPROVAL',
  AUTHORIZED_SIGNATORY_REJECTED: 'AUTHORIZED_SIGNATORY_REJECTED',
  AUTHORIZED_SIGNATORY_APPROVED: 'AUTHORIZED_SIGNATORY_APPROVED',
  PENDING_TRADER_APPROVAL: 'PENDING_TRADER_APPROVAL',
  REJECTED: 'REJECTED'
}
export const INVOICE_TAB = {
  RECEIVABLE_PENDING_CREDIT_AUTHORIZATION:
    'RECEIVABLE_PENDING_CREDIT_AUTHORIZATION',
  RECEIVABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL:
    'RECEIVABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL',
  RECEIVABLE_AUTHORIZED_SIGNATORY_APPROVED:
    'RECEIVABLE_AUTHORIZED_SIGNATORY_APPROVED',
  RECEIVABLE_REJECTED: 'RECEIVABLE_REJECTED',
  RECEIVABLE_SENT: 'RECEIVABLE_SENT',
  RECEIVABLE_PAID: 'RECEIVABLE_PAID',
  RECEIVABLE_CANCELLED: 'RECEIVABLE_CANCELLED',
  PAYABLE_PENDING_TRADER_APPROVAL: 'PAYABLE_PENDING_TRADER_APPROVAL',
  PAYABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL:
    'PAYABLE_PENDING_AUTHORIZED_SIGNATORY_APPROVAL',
  PAYABLE_AUTHORIZED_SIGNATORY_APPROVED:
    'PAYABLE_AUTHORIZED_SIGNATORY_APPROVED',
  PAYABLE_PAID: 'PAYABLE_PAID',
  PAYABLE_REJECTED: 'PAYABLE_REJECTED'
}
export const INVOICE_TYPE = {
  COMMERCIAL: 'COMMERCIAL',
  PROFORMA: 'PROFORMA'
}
export const INVOICE_TYPE_OPTIONS = [
  { label: 'Commercial', value: 'COMMERCIAL' },
  { label: 'Proforma', value: 'PROFORMA' }
]

// Credit Debit Note
export const CDNOTE_NOTE_TYPE = {
  CREDIT_NOTE: 'CREDIT_NOTE',
  DEBIT_NOTE: 'DEBIT_NOTE'
}
export const CDNOTE_TAB = {
  PENDING_CREDIT_AUTHORIZATION: 'PENDING_CREDIT_AUTHORIZATION',
  PENDING_AUTHORIZED_SIGNATORY_APPROVAL: 'PENDING_AUTHORIZED_SIGNATORY_APPROVAL',
  APPROVED: 'APPROVED',
  APPLIED: 'APPLIED',
  REJECTED: 'REJECTED'
}
export const CDNOTE_STATUS = {
  PENDING_CREDIT_AUTHORIZATION: 'PENDING_CREDIT_AUTHORIZATION',
  PENDING_AUTHORIZED_SIGNATORY_APPROVAL: 'PENDING_AUTHORIZED_SIGNATORY_APPROVAL',
  AUTHORIZED_SIGNATORY_APPROVED: 'AUTHORIZED_SIGNATORY_APPROVED',
  APPLIED: 'APPLIED',
  CREDIT_AUTHORIZATION_REJECTED: 'CREDIT_AUTHORIZATION_REJECTED',
  AUTHORIZED_SIGNATORY_REJECTED: 'AUTHORIZED_SIGNATORY_REJECTED'
}
export const CDNOTE_STATUS_LIST = [
  {
    label: 'Pending Credit Authorization',
    name: 'PENDING_CREDIT_AUTHORIZATION',
    status: 'PENDING_CREDIT_AUTHORIZATION'
  },
  {
    label: 'Pending Authoried Signatory',
    name: 'PENDING_AUTHORIZED_SIGNATORY_APPROVAL',
    status: 'PENDING_AUTHORIZED_SIGNATORY_APPROVAL'
  },
  {
    label: 'Approved',
    name: 'AUTHORIZED_SIGNATORY_APPROVED',
    status: 'AUTHORIZED_SIGNATORY_APPROVED'
  },
  {
    label: 'Applied',
    name: 'APPLIED',
    status: 'APPLIED'
  },
  {
    label: 'Reject',
    name: 'REJECTED',
    status: ['CREDIT_AUTHORIZATION_REJECTED', 'AUTHORIZED_SIGNATORY_REJECTED']
  }
]

// Finance
export const FINANCE_VIEW = {
  MAIN_FINANCE: 'MAIN_FINANCE',
  CREATE_FINANCE: 'CREATE_FINANCE',
  EDIT_FINANCE: 'EDIT_FINANCE'
}
export const FINANCE_NFT_STATUS = {
  CREATED: 'CREATED',
  APPROVED: 'APPROVED',
  SUPPORTING_DOCUMENTS_UPLOADED: 'SUPPORTING_DOCUMENTS_UPLOADED',
  MT103_UPLOADED: 'MT103_UPLOADED',
  COMPLETED: 'COMPLETED'
}

/*
"PENDING_APPROVAL(0, ""PENDING_APPROVAL"")
IN_PRINCIPAL_APPROVED(1, ""IN_PRINCIPAL_APPROVED"")
NOTICE_OF_ASSIGNMENT_ACKNOWLEDGEMENT(2, ""NOTICE_OF_ASSIGNMENT_ACKNOWLEDGEMENT"")
OUTSTANDING(3, ""OUTSTANDING"")
COMPLETED(4, ""COMPLETED"")"
*/
export const FINANCE_STATUS = [
  'PENDING_APPROVAL',
  'IN_PRINCIPAL_APPROVED',
  'NOTICE_OF_ASSIGNMENT_ACKNOWLEDGEMENT',
  'OUTSTANDING',
  'COMPLETED'
]
export const FINANCE_TAB_LIST = [
  {
    label: 'Pending Approval',
    name: 'PENDING',
    status: FINANCE_STATUS[0]
  },
  {
    label: 'Approved',
    name: 'APPROVED',
    status: `${FINANCE_STATUS[1]}, ${FINANCE_STATUS[2]}, ${FINANCE_STATUS[3]}`
  },
  {
    label: 'Completed',
    name: 'COMPLETED',
    status: FINANCE_STATUS[4]
  }
]
export const FINANCE_STEP_STATUS = [
  {
    label: 'Pending Approval',
    name: FINANCE_STATUS[0]
  },
  {
    label: 'In Principal Approved',
    name: FINANCE_STATUS[1]
  },
  {
    label: 'Notice of Assigment Acknowledgement (NOA Acknowledgement)',
    name: FINANCE_STATUS[2]
  },
  {
    label: 'Outstanding',
    name: FINANCE_STATUS[3]
  },
  {
    label: 'Completed',
    name: FINANCE_STATUS[4]
  }
]
export const FINANCE_STEPS = [
  {
    label: 'Pending Approval',
    name: FINANCE_STATUS[0]
  },
  {
    label: 'In Principal Approved',
    name: FINANCE_STATUS[1]
  },
  {
    label: 'Notice of Assigment Acknowledgement (NOA Acknowledgement)',
    name: FINANCE_STATUS[2]
  },
  {
    label: 'Outstanding',
    name: FINANCE_STATUS[3]
  },
  {
    label: 'Completed',
    name: FINANCE_STATUS[4]
  }
]
export const FINANCE_DUCUMENTS_TAB_LIST = [
  {
    label: 'Request Information',
    name: 'REQUEST_DOC'
  },
  {
    label: 'Other Supporting Documents',
    name: 'OTHER_DOC'
  }

]
// Term
export const TERM_CONTRACT_TAB_LIST = [
  {
    label: 'Ongoing',
    name: 'ONGOING',
    status: 'ONGOING'
  },
  {
    label: 'Expired',
    name: 'EXPEIRED',
    status: 'EXPEIRED'
  }
]
// Term
export const TERM_VIEW = {
  MAIN_TERM: 'MAIN_TERM',
  CREATE_TERM: 'CREATE_TERM',
  VIEW_TERM: 'VIEW_TERM',
  EDIT_TERM: 'EDIT_TERM'
}
// Order
export const ORDER_VIEW = {
  MAIN_ORDER: 'MAIN_ORDER',
  CREATE_ORDER: 'CREATE_ORDER',
  VIEW_ORDER: 'VIEW_ORDER',
  EDIT_ORDER: 'EDIT_ORDER',
  EDIT_ORDER_COMM: 'EDIT_ORDER_COMM'
}
export const ORDER_ITEM_TYPE = {
  PRODUCT: 'PRODUCT',
  OTHER_COST: 'OTHER_COST'
}
export const ORDER_PRODUCT_STATUS = {
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
  READY_FOR_INVOICING: 'READY_FOR_INVOICING',
  INVOICE_CREATED: 'INVOICE_CREATED'
}
export const ORDER_OTHER_COST_STATUS = {
  CONFIRMED: 'CONFIRMED',
  VERIFIED: 'VERIFIED',
  READY_FOR_INVOICING: 'READY_FOR_INVOICING',
  INVOICE_CREATED: 'INVOICE_CREATED'
}
export const ORDER_ITEM_TYPE_OPTIONS = [
  { label: 'Product', value: 'PRODUCT' },
  { label: 'Other Cost', value: 'OTHER_COST' },
  { label: 'Commission', value: 'COMMISSION' }
]
export const ORDER_OTHER_COST_DESCRIPTION_OPTIONS = [
  { label: 'Barging Lumpsum', value: 'Barging Lumpsum' },
  { label: 'Frieght', value: 'Frieght' },
  { label: 'Oil Boom', value: 'Oil Boom' },
  { label: 'Safety Boat', value: 'Safety Boat' },
  { label: 'Surveyor Fees', value: 'Surveyor Fees' },
  { label: 'Agent Fees', value: 'Agent Fees' },
  { label: 'Commission Fees', value: 'Commission Fees' }
]
export const ORDER_PRICE_TYPE_OPTIONS = [
  { label: 'Fixed', value: 'FIXED' },
  { label: 'Formula', value: 'FORMULA' }
]
export const ORDER_PRICE_TYPE = {
  FIXED: 'FIXED',
  FORMULA: 'FORMULA'
}
export const ORDER_PRICE_RATE_TYPE_OPTIONS = [
  { label: 'Bunkerwire', value: 'BUNKERWIRE' },
  { label: 'MOPS', value: 'MOPS' },
  { label: 'Ex-Wharf', value: 'EX_WHARF' }
]
export const ORDER_PRICE_RATE_TYPE_METRIC_OPTIONS = [
  { label: 'Low', value: 'LOW' },
  { label: 'Mid', value: 'MID' },
  { label: 'High', value: 'HIGH' }
]
export const ORDER_PRICE_PERIOD = [
  'Date of Delivery (DOD)',
  'Date of Delivery (DOD) - Start Pump',
  'Date of Delivery (DOD) - Stop Pump',
  'Date of Nomination (DON)',
  'Whole Monthly Average (WMA)',
  '3 Day Average',
  '5 Day Average',
  '5 Day Average before Date of Delivery',
  'Average of 01st to 15th of the month',
  'Average of 16th to end of the month',
  '23rd to Month End',
  'Specific Date Range'
]
export const ORDER_PRICE_PREMIUM_TYPE_OPTIONS = [
  { label: 'FLAT', value: 'FLAT' },
  { label: '+', value: 'POSITIVE' },
  { label: '-', value: 'NEGATIVE' }
]
export const ORDER_MODE_OPTIONS = [
  { label: 'Term', value: 'TERM' },
  { label: 'Spot', value: 'SPOT' }
]
export const TERM_CONTRACT_TYPE = {
  DELIVERED: 'DELIVERED',
  EX_WHARF: 'EX_WHARF',
  COA: 'CONTRACT_OF_AFFREIGHTMENT',
  TC: 'TIME_CHARTER'
}
export const ORDER_TYPE = {
  DELIVERED: 'DELIVERED',
  EX_WHARF: 'EX_WHARF',
  BARGING_NOMINATION: 'BARGING_NOMINATION'
}
export const ORDER_TRADE_TYPE = {
  PURCHASE: 'PURCHASE',
  SALES: 'SALES',
  PURCHASE_AND_SALES: 'PURCHASE_AND_SALES'
}
export const ORDER_STATUS = {
  DRAFT: 'DRAFT',
  CONFIRMED: 'CONFIRMED',
  CANCELLED: 'CANCELLED',
  PENDING_SELLER_CONFIRMATION: 'PENDING_SELLER_CONFIRMATION',
  PENDING_BUYER_CONFIRMATION: 'PENDING_BUYER_CONFIRMATION',
  PENDING_SELLER_CANCELLATION: 'PENDING_SELLER_CANCELLATION',
  PENDING_BUYER_CANCELLATION: 'PENDING_BUYER_CANCELLATION',
  PENDING_CREDIT_AUTHORIZATION: 'PENDING_CREDIT_AUTHORIZATION',
  CREDIT_AUTHORIZATION_REJECTED: 'CREDIT_AUTHORIZATION_REJECTED'
}
export const ORDER_COMM_UOM_TYPE_OPTIONS = [
  { label: 'LSUM', value: 'LUMPSUM' },
  { label: 'MT', value: 'MT' },
  { label: 'CBM', value: 'CBM' }
]

/* table style */
export const HEADER_CELL_STYLE = {
  background: '#F4F6FA',
  alignSelf: 'center',
  textAlign: 'left',
  fontSize: '13px'
}
export const CELL_STYLE = {
  alignSelf: 'center',
  textAlign: 'left',
  padding: '5px',
  fontSize: '12px'
}
export const SMALL_CELL_STYLE = {
  alignSelf: 'center',
  textAlign: 'left',
  padding: '0px !important',
  fontSize: '11px'
}

export const COMPANY_TYPE = {
  TRADING: 'Trading',
  AGENT: 'Agent',
  LAWYER: 'Lawyer',
  LAB: 'Lab',
  FINANCIAL_SERVICE_PROVIDER: 'Financial Service Provider'
}

export const COMPANY_SETTINGS_PROPERTIES = {
  trialEbdn: { displayText: 'Trial EBDN', isRestricted: true },
  creditCheck: { displayText: 'Credit Authorization', isRestricted: false },
  cargoOfficerTransferJob: { displayText: 'Transfer Job by Cargo Officer', isRestricted: false },
  cargoOfficerCancelJob: { displayText: 'Cancel Job by Cargo Officer', isRestricted: false },
  sgTradexParticipantId: { displayText: 'SG Tradex Pariticipant Id', isRestricted: true },
  sgTradexPushAfter: { displayText: 'SG Tradex Push B2B After', isRestricted: true },
  pushToMpaViaSgTradex: { displayText: 'Push To MPA Via SgTradex', isRestricted: true },
  pushB2BViaSgTradex: { displayText: 'Push B2B Via SgTradex', isRestricted: true },
  pushB2BViaSgTradexToSelf: { displayText: 'Push B2B Via SgTradex To Self', isRestricted: true },
  ebdnDefaultRecipients: { displayText: 'EBDN Default Recipients', isRestricted: false },
  decimalPoints: { displayText: 'Decimal Points', isRestricted: true },
  roundingDecimal: { displayText: 'Rounding Decimal Type', isRestricted: true },
  setPricingTier: { displayText: 'Set Pricing Tier', isRestricted: true },
  exxonMobil: { displayText: 'Is Exxon Mobil', isRestricted: true },
}

export const GENERAL_EMAIL_RULE = [
  { required: false, message: 'Please enter a valid email address.', trigger: ['blur', 'change'] },
  { type: 'email', message: 'Please enter a valid email address.', trigger: ['blur', 'change']}
]

export const GENERAL_EMAIL_RULE_REQ = [
  { required: true, message: 'Please enter a valid email address.', trigger: ['blur', 'change'] },
  { type: 'email', message: 'Please enter a valid email address.', trigger: ['blur', 'change']}
]

export const LICENSE_NUMBKER_RULE = [{
  pattern: /^S[A|B|C|P|R|T]{1} [0-9]{4}[a-zA-Z]{1}$/,
  message: 'Invalid license number. Valid format: S[A|B|C|P|R|T] NNNNA',
  trigger: 'blur'
}]

export const EBDN_AUDIT_TRAIL_EVENT = {
  TRANSACTION_STARTED: 'TRANSACTION_STARTED',
  JOB_TRANSFERRED: 'JOB_TRANSFERRED',
  BEFORE_DELIVERY_SIGNED: 'BEFORE_DELIVERY_SIGNED',
  AFTER_DELIVERY_SIGNED: 'AFTER_DELIVERY_SIGNED',
  BEFORE_DELIVERY_SIGNATURES_CLEARED: 'BEFORE_DELIVERY_SIGNATURES_CLEARED',
  AFTER_DELIVERY_SIGNATURES_CLEARED: 'AFTER_DELIVERY_SIGNATURES_CLEARED',
  LOGGED_IN: 'LOGGED_IN',
  EMAIL_SENT_TO_GUEST: 'EMAIL_SENT_TO_GUEST',
  VERIFIABLE_DOCUMENT_GENERATED: 'VERIFIABLE_DOCUMENT_GENERATED',
  JOB_CANCELLED: 'JOB_CANCELLED'
}
export const TRANSFER_TYPE = {
  SHIP: 'Ship Transfer History',
  CO: 'Cargo Officer Transfer History'
}
export const TRANSFER_STATUS = {
  TRANSFER: 'TRANSFER',
  TERMINATE: 'STOP TRANSFER'
}

export const OWNER_TYPE = {
  0: 'OWNER',
  1: 'OPERATOR',
  2: 'MASTER',
  3: 'OWNER / OPERATOR',
  4: 'MASTER / OWNER',
  5: 'MASTER / OPERATOR',
  6: 'MASTER / OWNER / OPERATOR',
}

export const ULLAGING_SAMPLING_INSPECTION_OPTION = {
  0: 'Empty',
  1: 'Ullaging',
  2: 'Sampling',
  3: 'Inspection'
}

export const LOCATION_ANCHORAGE_OPTION = {
  0: 'Empty',
  1: 'Location',
  2: 'Anchorage'
}

export const ANCHORAGE_BERTH_OPTION = {
  0: 'Empty',
  1: 'Anchorage',
  2: 'Berthing'
}

export const SULPHUR_CONTENT_LIMIT_TYPE = {
  0: 'Empty',
  1: 'the limit value given by regulation 14.1 of this Annex;',
  2: 'the limit value given by regulation 14.4 of this Annex; or',
  3: 'the purchasers limit value of ...'
}

export const SUPPLY_TYPE_OPTIONS = [
  { label: 'International (I)', value: 'I' },
  { label: 'Domestic (D)', value: 'D' }
]
export const MPA_FUEL_BUNKER_TYPE_FLAG = {
  OTHERS: 'OTHERS',
  BIOFUEL: 'BIOFUEL',
  N: 'N',
  Y: 'Y'
}
export const YES_NO_OPTION_CHAR = [
  {
    value: 'Y',
    label: 'Yes'
  },
  {
    value: 'N',
    label: 'No'
  }
]
export const REQ_RULE =[
  {
    required: true,
    message: "This field is required",
    trigger: ["change", "blur"],
  }
]
export const DATETIME_FORMAT = {
  GEO_DATETIME: 'dd-MM-yyyy HH:mm:ss',
  GEORGIAN_DATETIME: 'dd/MM/yyyy HH:mm:ss',
  GEORGIAN_DATE: 'dd/MM/yyyy',
  OTH_DATETIME: 'yyyy-MM-dd HH:mm:ss',
  OTH_DATE: 'yyyy-MM-dd',
  VALUE_FORMAT: 'yyyy-MM-dd HH:mm:ss',
  DATETIME_PLACEHOLDER: 'Select datetime',
  DEFAULT_TIME: '12:00:00'
}

export const PRODUCT_TYPE = {
  FUEL_OIL: 'FUEL_OIL',
  GAS_OIL: 'GAS_OIL',
  METHANOL: 'METHANOL',
  BIODIESEL: 'BIODIESEL',
  BIOFUEL: 'BIOFUEL',
}
export const OIL_TYPE_OPTIONS = [
  { label: 'Marine Gas Oil', value: 'GAS_OIL' },
  { label: 'Marine Fuel Oil', value: 'FUEL_OIL' },
  { label: 'Methanol', value: 'METHANOL' },
  { label: 'Biodiesel', value: 'BIODIESEL' },
  { label: 'Biofuel', value: 'BIOFUEL' },
]
export const SAGE_GROUP_CODE_OPTIONS= [
  { label: 'ADHOC', value: 'ADHOC' },
  { label: 'SC', value: 'SC' },
]
export const SAGE_GROUP_CODE_MAP = {
  ADHOC: 'ADHOC',
  SC: 'SC',
}
export const VICTORY_SG_PRODUCT = 'LSMGO DMA 0.1%S MAX'
